@import '../../../scss/theme/variables';
@import '../../../scss/styles/mixins';
@import '../../../scss/theme/breakpoints';

.add-to-favoritos-btn {
  color: $primary;
  text-align: center;
  cursor: pointer;

  span {
    background-color: $white;
    font-weight: 500;
    width: 100%;

    &:hover {
      box-shadow: 2px 2px 10px $boxShadow;
    }
  }

  &__added {
    background-color: $primary !important;
    color: $white;
    font-weight: 400 !important;
  }

  &__disabled {
    span {
      box-shadow: none !important;
    }
  }
}