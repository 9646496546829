@import '../../../../../../scss/styles/mixins';
@import '../../../../../../scss/theme/breakpoints';
@import '../../../../../../scss/theme/variables';

.skeleton-card-each-seguimiento {
  height: 192px;

  @include lg {
    height: 65px;
  }

  &__envio {
    @include skeletonBasics(15px, 80px, 3px);
    margin-bottom: 6px;
  }
  
  &__fecha {
    @include skeletonBasics(13px, 120px, 3px);
  }
  
  &__contenido {
    @include flexCol(flex-start, flex-start);
    gap: 3px;

    @include lg {
      gap: 5px;
    }
    
    &--text1 {
      @include skeletonBasics(14px, 160px, 3px);

      @include lg {
        height: 16px;
      }
    }
    &--text2 {
      @include skeletonBasics(14px, 120px, 3px);
      @include lg {
        height: 16px;
      }
    }
  }

  &__title {
    @include skeletonBasics(10px, 70px, 3px);
    margin: 7px 0 8px;
  }
  
  &__ref {
    @include skeletonBasics(14px, 100px, 3px);
  }
  
  &__transporte {
    @include skeletonBasics(14px, 100px, 3px);
  }
}

.each-seguimiento-loading-animation {
  @include skeletonBasicsAnimation($btnFotos, $skeletonSecondary);
}