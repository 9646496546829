@import '../../../scss/theme/variables';
@import '../../../scss/theme/breakpoints';
@import '../../../scss/styles/mixins';


.contacto {
    padding: 110px 0 70px;
    width: 100%;
    @include flexCol(center, center);

    @include md {
        padding-top: 155px;
    }

    &__info {
        @include flexCol(center, center);

        &--title {
            padding-bottom: 16px;
            @include primaryText(26px);

            @include md {
                font-size: 42px;
            }
        }

        &--subtitle {
            text-align: center;
            padding-bottom: 32px;
            @include primaryText(14px);
            font-weight: 500;

            @include md {
                font-size: 16px;
            }
        }

        &--contact-container {
            @include flexRow(center, center);
            gap: 48px;
            padding-bottom: 36px;

            @include md {
                padding-top: 18px;
                padding-bottom: 42px;
            }
            &__contact{
                @include flexCol(center, center);
                gap: 12px;
                width: 140px;
                
                @include md {
                    flex-direction: row;
                    width: auto;
                }
                
                a {
                    font-weight: 500;
                    @include primaryText(0.75rem);

                    &:link, &:visited, &:active {
                        text-decoration: none;
                    }
                    
                    @include sm {
                        font-size: 0.8rem;
                    }

                    @include md {
                        font-size: 16px;
                    }
                }
        
                &--icon {
                    height: 42px;
                    width: 42px;
                    box-shadow: 0px 0px 10px $boxShadow;
                    border-radius:6px;
                    @include primaryText(24px);
                    @include flexCol(center, center);
                    
                }
        
            }
        }
    }

    &__form {
        @include flexCol(center, center);
        width: 100%;
    }
}