@import './scss/theme/typography';
@import './scss/theme/variables';
@import './scss/theme/breakpoints';

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $rubik;
}

:root {
  --toastify-color-success: #215732 !important;
  --swiper-theme-color: #215732 !important;
  --swiper-navigation-size: 28px !important;
}

.Toastify {
  z-index: 200000 !important;
  @include mdMax {
    position: fixed;
  }
}

.img-default {
  object-fit: cover !important;
}
