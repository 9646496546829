@import '../../../../../../scss/styles/mixins';
@import '../../../../../../scss/theme/breakpoints';
@import '../../../../../../scss/theme/variables';


.drop-down-card {
    padding: 6px 13px 13px;
    
    &__alert {
        @include flexRow(flex-start, flex-start);
        background-color: $white;
        padding: 10px 14px 10px 10px;
        border-radius: 10px;
        font-size: 12px;
        
        &--icon {
            padding-right: 8px;
        }
        
        &--tlf {
            color: $primary;
            font-weight: 600;
        }
        
        &--num-envio {
            font-weight: 600;
        }
    }
    
    h4 {
        font-weight: 600 !important;
        padding: 16px 0;
    }
    
    &__main-container {
        @include lg {
            @include flexRow(flex-end, space-between);
            padding-bottom: 16px;
        }

    }
    

    &__estado-container {
        border-bottom: 1px solid $grayColor;

        @include lg {
            width: 52%;
        }

        p {
            display: grid;
            grid-template-columns: 1.3fr 1.1fr;
            @include grayText(10px);
            padding-bottom: 12px;
            
            @include sm {
                grid-template-columns: 1.2fr 1.3fr;
            }
        }
        
        div {
            padding: 8px 0;
            border-top: 1px solid $grayColor;
            font-size: 12px;
            display: grid;
            grid-template-columns: 1.3fr 1.1fr;
            
            @include sm {
                grid-template-columns: 1.2fr 1.3fr;
            }

            span:nth-child(even) {
                font-weight: 600;
            }
        }

        &--primary-color {
            color: $primary;
        }
    }
    
    &__medidas-container {
        @include flexRow(flex-start, flex-start);
        gap: 24px;
        padding: 26px 10px 5px;
        
        p {
            @include flexCol(flex-start, flex-start);
            span:nth-child(odd) {
                @include grayText(10px);
                padding-bottom: 5px;
            }
            span:nth-child(even) {
                font-size: 14px;
            }
        }
    }
}