@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.pedido-card {
    background-color: $btnFilterBg;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.5fr;
    row-gap: 20px;
    padding: 14px;
    width: 100%;
    margin-bottom: 26px;

    @include xl {
        grid-template-columns: 1.1fr 2.4fr 1fr 1fr 1fr 0.8fr;
    }

    &__column {
        @include flexCol(flex-start, flex-start);
        gap: 4px;
        font-size: 12px;

        @include xl {
            justify-content: center;
            align-items: center;
        }
        
        &.triple-column {
            width: 100%;
            justify-content: flex-start;
            @include xlMax {    
                display: grid;
                grid-column: 2/5;
                grid-template-columns: 3fr;
            }
            
            .triple-column-div {
                display: grid;
                grid-template-columns: 1.3fr 1.1fr;
                width: 100%;
                gap: 18px;

                @include lg {
                    grid-template-columns: 1.7fr 1.1fr;
                }
            }
            @include xl {
                gap: 16px;
                align-self: center;
                grid-column: 2/3;

                span {
                    align-self: center;
                }
            }
        }
        
        &.doble-column {
            @include xlMax {
                grid-column: 3/5;
            }
        }

        &.left-align {
            justify-self: flex-start;
            align-items: flex-start;
        }

        &--id {
            @include flexRow(flex-start, center);
            gap: 1px;
            font-weight: 500;
            @include primaryText(14px);
            text-decoration: underline;
            @include custom(1350px) {
                font-size: 16px;
            }

            cursor: pointer;

            svg {
                font-size: 16px;
            }
        }

        &--fecha {
            font-size: 13px;
        }

        &--nombre {
            @include primaryText(16px);
            font-weight: 500;
            @include capitalize();
        }

        &--modelo {
            font-size: 15px;
            font-weight: 300;

            @include xl {
                max-width: 130px;
            }
        }

        &--title {
            font-size: 10px;

            @include xl {
                display: none;
            }
        }

        &--precio {
            font-size: 14px;
        }
    }

    &__acciones {
        @include flexRow(center, center);
        gap: 10px;
        padding: 0 10px 0 12px;
        
        @include xlMax {
            grid-column: 1/5;
            padding-top: 16px;
            border-top: 1px solid $grayColor;
        }

        div {
            @include flexCol(center, center);
            gap: 10px;
            text-align: center;
            max-width: 75px;
            @include grayText(12px);
            cursor: pointer;

            p:first-child {
                @include flexCol(center, center);
                border: 1px solid $grayColor;
                width: 36px;
                height: 36px;
                border-radius: 5px;
                font-size: 24px;
                position: relative;

                &:active {
                    -webkit-transform: scale(0.98);
                    transform: scale(0.98);
                }

                @include xl {
                    border-radius: 12px;
                }
            }
        }
    }

    .max-incidencias-permitido {
        opacity: 0.7;
    }

    .actions-menu {
        @include flexRow(center, center);
    }

}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    li {
        @include flexRow(center, flex-start);
        gap: 6px;
        p {
            position: relative;
            width: 16px;
        }
    }
}