@import '../../../../scss/styles/mixins';
@import '../../../../scss/theme/breakpoints';
@import '../../../../scss/theme/variables';

.change-language {     
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem 0;
  border: none;
  color: $primary;
  @-moz-document url-prefix() {
    gap:4px;
  }
  
  &:hover select {
    @include mdMax {
      background-color: $backgroundGray;
    }
  }
  
  &__select {
      color: $primary;
      cursor: pointer;
      border: none !important;
      outline: none !important;
      background: $white;
  }

}