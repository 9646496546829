@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.each-dir-card {
    @include basicsCardDireccion();
    gap: 15px;
    position: relative;

    &.out-of-range {
        opacity: 0.7;
        cursor: not-allowed;
        
        &>div {
            width: 100%;
            padding: 0;
            
            @include lgMax {
                max-width: none !important;
            }
        }
    }

    &__text-out-of-range {
        color: $danger;
        font-size: 9px !important;
        text-align: end;

        @include lg {
            position: absolute;
            right: 10px;
            bottom: 12px;
        }
        
    }

    &__direccion {
        max-width: 70%;
    }
    
    &__btns {
        @include flexRow(center, flex-end);
        gap: 6px;
        padding-right: 3px !important;

        span {
            @include btnEditDelete();

            &:active {
                -webkit-transform: scale(0.98);
                transform: scale(0.98);
            }
        }
    }
}