@import '../../../scss/theme/variables';
@import '../../../scss/styles/mixins';

.pagination-container {
    @include flexRow(center, flex-end);
    width: 100%;
    height: 140px;
    list-style-type: none;
    padding-bottom: 70px;
  
    .pagination-item {
      padding: 0 12px;
      height: 32px;
      width: 32p;
      text-align: center;
      margin: auto 1px;
      @include primaryText(16px);
      display: flex;
      box-sizing: border-box;
      align-items: center;
      letter-spacing: 0.01071em;
      border-radius: 8px;
      line-height: 1.43;
      min-width: 32px;

      &.arrow-right, &.arrow-left {
        border: 1px solid $primary;
        @include primaryText(2rem);
        width: 45px;
        height: 32px;
      }

      &.arrow-right {
        margin-left: 9px;
      }

      &.arrow-left {
        margin-right: 9px;
      }
  
      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }
      &:hover {
        background-color: $btnFilterBg;
        cursor: pointer;
      }
  
      &.selected {
        background-color: $primaryLight;
      }
  
      &.disabled {
        pointer-events: none;
  
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }