@import '../../../../scss/theme/variables';
@import '../../../../scss/theme/breakpoints';
@import '../../../../scss/styles/mixins';

.mis-datos {
    @include basicsEachSectionAreaPersonal();
    
    &__return {
        @include returnArrowAreaPersonal();
    }

    h2 {
        @include titleAreaPersonal();
    }

    h3 {
        @include primaryText(14px);
        padding-bottom: 16px;

        @include md {
            font-size: 22px;
            padding-bottom: 18px;
        }
    }

    &__datos {
        width: 100%;
        padding-bottom: 100px;

        @include lg {
            @include flexRow(flex-start, space-between);
            flex-wrap: wrap;
            gap: 14px;
            max-width: 950px;
        }

        div {
            @include flexCol(flex-start, flex-start);
            width: 100%;
            font-size: 13px;
            gap: 4px;
            padding-top: 10px;

            @include lg {
                width: 48.5%;
                padding-top: 2px;
            }

            @include xl {
                width: 49%;
            }
        }

        &--full-width {
            width: 100% !important;

            &.btn-container {
                @include flexRow(flex-end, flex-end);

                button {
                    @include button(100%, 40px, 10px);
                    margin-top: 8px;
                    cursor: pointer;

                    @include sm {
                        width: 180px;
                    }
                }
            }
        }

        &--info {
            @include flexRow(center, flex-start);
            @include basicsInputAreaPersonal();        
        }
    }

    .skeleton-input {
        @include skeletonInput();
    }
}