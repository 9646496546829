@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.each-filter {
    width: 100%;
    padding: 6px 16px;
    
    @include md {
        padding: 6px 0;
    }
    
    @include xl {
        width: 420px;
    }
    
    &__btn {
        @include flexRow(center, space-between);
        width: 100%;
        height: 43px;
        background-color: $btnFilterBg;
        border-radius: 10px;
        padding: 0 16px;
        gap: 16px;
        
        @include xl{
            height: 48px;
            padding: 0 20px;
        }
        
        @include activeAndDisableFilterBtn();
        
        &--nombre {
            color: $primary;
            font-size: 15px;
            font-weight: 600;
            @include capitalize();

            @include xl {
                font-size: 17px;
            }
            
            &.filter-selected {
                color:$default;
                font-weight: 400;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                text-transform: uppercase;
                max-width: 173px;
            }
        }
        
        &--dropdown {
            @include flexRow(center, flex-end);
            gap: 5px;
            
            @include xl {
                gap: 9px;
            }
            
            &--text {
                @include grayText(12px);
                cursor: pointer;
                min-width: 70px;
                
                @include xl {
                    font-size: 13px;
                }
            }
            
            &--arrow {
                cursor: pointer;
                font-size: 20px;
                display: flex;
                align-items: center;
            }
        }
    }
    
    &__search-input {
        padding-bottom: 3px;
        position: relative;
        input {
            width: 100% !important;
            border: none;
            cursor: auto !important;
            border-bottom: 1px solid $btnFilterBg !important;
            outline: none;
            padding-bottom: 6px;
            height: auto !important;
        }

        &--reset {
            position: absolute;
            right: 2px;
            top: -4px;
            cursor: pointer;
            color: $grayColor;
            font-size: 13px;
        }
    }

    ul {
        list-style: none;
        border: 2px solid $btnFilterBg;
        margin-top: -15px;
        padding: 26px 16px 12px;
        line-height: 26px;
        border-radius: 0px 0px 10px 10px;
        max-height: 215px;
        overflow-y: scroll;

        @include scrollbar($backgroundGray, $white);     

        @include md {
            max-height: 130px;
        }

        li {
            @include flexRow(center, flex-start);
            gap: 6px;
            
            label {
                width: 100%;
                cursor: pointer;
                gap: 6px;
                font-size: 14px;
                text-transform: uppercase;
            }
            input {
                @include inputCheckbox();
            } 
        }
    }

    &__no-results {
        color: $danger;
        font-size: 12px;
        line-height: 15px;
        padding-top: 2px;
        @include flexRow(flex-start !important, flex-start);
        gap: 3px !important;

        span {
            margin-top: 1px;
        }
    }
}