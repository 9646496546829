@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.modal-seguimiento {
    .modal-container__container {
        display: block;
        unicode-bidi: isolate;
        min-height: 520px;
    }
   
    h2 {
        padding: 5px 0 24px;
    }

    h5 {
        font-size: 10px;
        font-weight: 400;
        padding-bottom: 5px;
    }

    h4 {
        font-size: 14px;
        font-weight: 400;
    }

        &__alert {
            font-size: 12px;
            font-weight: 600;
            @include flexRow(center, flex-start);
            gap: 9px;
            padding: 20px 0 10px;

            svg {
                font-size: 14px;
            }
        }

        &__paquetes {
            @include primaryText(13px);
            font-weight: 600;
            padding-bottom: 16px;
        }

        &__one-paquete {
            height: 52px;
        }

        &__titles {
            display: grid;
            grid-template-columns: 1fr 1.5fr;
            padding: 0 13px;
            @include grayText(10px); 

            @include lg {
                grid-template-columns: 1.1fr 1.7fr 1fr 1fr 1.5fr;
                margin-right: 16px;
                padding-right: 0;
            }
            
            @include xl {
                margin-left: 55px;
            }

            &--center {
                text-align: center;
            }
        }

        &__desktop {
            @include flexRow(flex-start, flex-start);
            width: 100%;
            gap: 16px;
            
            &--icon {
                width: 42px;
                height: 42px;
                background-color: $primaryLight;
                @include flexCol(center, center);
                border-radius: 12px;
                margin-top: 18px;

                img {
                    width: 22px;
                }
            }
        }

        &__no-data {
            background-color: $btnFilterBg;
            border-radius: 8px;
            margin: 6px 0 20px;
            width: 100%;
            height: 192px;
            @include flexCol(center, center);
            
            @include lg {
                height: 65px;
            }
        }
}