@import '../../../../scss/theme/variables';
@import '../../../../scss/theme/breakpoints';
@import '../../../../scss/styles/mixins';

.favoritos {
  @include basicsEachSectionAreaPersonal();
  padding-bottom: 60px !important;
  
  @include md {
    padding-bottom: 140px !important;
    padding-left: 80px;
    padding-right: 55px;
}

@include xl {
    max-width: 1200px;
    padding-left: 40px;
    padding-right: 24px;
}

@include custom(1300px) {
    padding-left: 80px;
    padding-right: 55px;

}

  &__return {
    @include returnArrowAreaPersonal();
  }

  h2 {
    @include titleAreaPersonal();
    padding-bottom: 3px;

    @include xl {
        padding-bottom: 9px;
    }
  }

  &__list {
    padding-top: 30px;
    @include flexCol(flex-start, flex-start);

    &--titles {
    display: grid;
    grid-template-columns: 5.5fr 1fr 1.5fr;
    width: 100%;
    font-size: 11px;
    padding: 6px;

    @include lg {
      grid-template-columns: 9.7fr 2fr 3fr;
    }

    @include xl {
      grid-template-columns: 7.5fr 3fr 1.8fr 1.8fr 0.7fr;
    }

    @include custom(1300px) {
      grid-template-columns: 7.2fr 3fr 1.8fr 1.8fr 0.7fr;
    }

    &--estado-stock {
      justify-self: center;
    }

      &--precio {
        justify-self: center;

        @include lgMax {
          display: none;
        }
      }
    }

    &--cards {
      @include flexCol(flex-start, flex-start);
      gap: 10px;
    }

    &--no-data {
      @include basicsNoData(109px);
      align-items: center;
      padding-right: 30px;
      
      @include xl {
        min-width: 820px;
      }
    }
  }

}