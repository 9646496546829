@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.modal-nueva-incidencia {
    .modal-container__container {
        display: block;
        unicode-bidi: isolate;
        min-height: 520px;

        @include sm {
            padding: 32px 55px;
        }

        @include md {
            min-height: 60%;
            padding: 32px 80px;
        }

        @include lg {
            padding: 32px 40px;

            &.container-second-step {
                padding: 32px 25%;
            }
    
            &.container-step0 {
                padding: 32px 30%;
            }
        }
    }

    @include custom(1400px) {
        padding: 140px 10%;
    }

    @include custom(1600px) {
        padding: 8% 15%;
    }
    
    a:link, a:visited, a:active {
        color: $primary;
        font-weight: 600;
        text-decoration:none;
    }
    
    &__title {
        padding-top: 20px;
        width: 100%;
        text-align: center;
        @include primaryText(22px !important);
        font-weight: 700 !important;
    }

    &__subtitle {
        @include primaryText(16px);
        font-weight: 300;
        width: 100%;
        text-align: center;
        padding: 13px 0 30px;
    }

    &__incidencias {
        @include flexCol(flex-start, flex-start);
        gap: 13px;

        &.container-step0 {
            @include flexCol(flex-start, flex-start);
            padding: 24px 2px;

            @include md {
                padding: 32px 25%;
            }
        
        }

        @include lg {
            flex-direction: row;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            padding-bottom: 20px;
        }

        &--each-container {
            @include flexCol(center, center);
            gap: 8px;
            text-align: center;
            padding: 18px 46px;
            border: 1px solid $primary;
            border-radius: 8px;
            cursor: pointer;
            width: 100%;

            @include lg {
                width: 48%;
                height: 140px;
            }

            @include xl {
                width: 32%;
                height: 155px;
                padding: 18px 25px
            }

            h4 {
                @include primaryText(17px);
                font-weight: 600;
            }

            &.tipo-active {
                background-color: $primaryLight;
            }
        }
    }

    &__btn-container {
        padding: 24px 0;

        @include lg {
            @include flexRow(center, center);

        }

        button {
            @include button (100%, 38px, auto);
            border-radius: 6px;
            font-size: 15px;
            cursor: pointer;
            position: relative;
        }

        &.btn-step0 {
            padding: 24px 2px;

            button {
                max-width: 150px;
            }
        }
        
        &--btn {
            @include lg {
                width: 150px !important;
                height: 42px !important;     
            }
        }

    }

    &__comentarios {
        width: 100%;
        resize: none;
        height: 110px;
        padding: 10px;
        border-radius: 6px;
        font-size: 15px;
        border: 1px solid $primary;
        outline-color: $primary;

        &::placeholder {
            color: $primary;
        }
    }

    &__prueba-grafica {
        color: $primary;
        padding: 32px 16px;
        text-align: center;
    }

    &__fotos {
        width: 100%;
        border: 1px solid $primary;
        height: 38px;
        border-radius: 6px;
        @include flexRow(center, space-between);
        padding: 12px;

        span {
            color: $primary;
        }

        label {
            font-size: 12px;
            border: 1px solid $grayColor;
            background-color: $btnFotos;
            padding: 2px 10px;
            border-radius: 4px;
            cursor: pointer;

            &:active {
                -webkit-transform: scale(0.99);
                transform: scale(0.99);
            }
        }

        input {
            display: none;
        }
    }

    &__fotos-names {
        @include flexCol(flex-start, flex-start);
        padding: 10px 0;
        gap: 3px;

        &--title {
            @include primaryText(14px);
            padding-left: 2px;
        }

        &--archivos {
            @include grayText(13px);
            @include flexCol(flex-start, flex-start);
            padding-left: 16px;
            gap: 2px;

            span {
                @include flexRow(center, flex-start);
                gap: 3px;
            }
        }

    }

    &__condiciones {
        @include flexRow(flex-start, flex-start);
        gap: 10px;
        padding: 29px 0 10px;

        input {
            @include inputCheckbox();
            margin-top: 2px;
        }

        p {
            @include primaryText(15px);
        }
    }
}