@import '../../../../scss/main.scss';

.related-parts {
  text-align: justify;
  padding: 70px 16px 0px;
  @include flexCol(flex-start, flex-start);
  gap: 16px;
  line-height: 26px;
  width: 100%;

  &__title {
    @include primaryText(19px);
    padding-bottom: 13px;

    @include md {
      font-size: 21px;
    }
  }

  &__container {
    @include flexRow(flex-start, flex-start);
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;

    @include lg {
      gap: 16px;
      
    }
    
    &.space-between {
      justify-content: space-between;
    }

  }

  &__skeleton {
    width: 500px !important;

    &--img {
      display: flex;
      height: 140px;
      
      @include lg {
        height: 160px;
      }
    }

    &--title {
      display: flex;
      width: 70px;
      height: 8px;
      margin: 16px;
    }

    &--text {
      display: flex;
      width: 110px;
      height: 7px;
      margin: 16px;
    }

    &--bottom {
      height: 60px;

      &--price {
        width: 50px;
        height: 16px;
        margin-left: 16px;
      }

      &--cart {
        width: 28px;
        height: 28px;
        margin-right: 16px
      }
    }
  }

}

.loading-sk-animation{
  @include skeletonBasicsAnimation($skeletonPrimary, $skeletonSecondary);
}

.related-part-card {
  width: 46%;
  min-height: 300px;
  border: 1px solid $boxShadow;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
  max-width: 46%;
  padding-bottom: 14px;

  @include custom(700px) {
    max-width: 30%;
  }

  @include lg {
    max-width: 15%;
  }

  &__img {
    width: 100%;
    
    img {
      width: 100%;
      height: 140px;
      object-fit: cover;

      @include lg {
        height: 160px;
      }

    }
  }

  &__info {
    padding: 0 14px;
    position: relative;
    @include flexCol(flex-start, space-between);
    gap: 16px;
    min-height: 150px;

    h4 {
      @include primaryText(14px);
      line-height: 18px;
      text-transform: lowercase;
      padding-bottom: 6px;
      text-align: start;
      &::first-letter {
        text-transform: capitalize;
      }
    }
    
    h5 {
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
      text-align: start;
    }

    &--bottom {
      @include flexRow(flex-end, space-between);
      width: 100%;

      &--price {
        @include flexCol(flex-start, flex-start);
        line-height: 18px;
        font-size: 20px;
        font-weight: 600;
        color: $primary;
        margin-bottom: -5px;
        span {
          font-size: 10px;
          color: $default;
          font-weight: 300;
        }
      }

      &--cart {
        cursor: pointer;
        border-radius: 6px;
        box-shadow: 1px 1px 2px $boxShadow;
        
        &--icon {
          @include flexCol(center, center);
          background-color: $primaryLight;
          font-size: 23px;
          width: 34px;
          height: 34px;
          position: relative;
          color: $primary;
          border-radius: 6px;
        }

        &--text {
          display: none;
        }
      }
    }
  }
}