@import '../../../scss/theme/variables';
@import '../../../scss/theme/breakpoints';
@import '../../../scss/styles/mixins';

.condiciones-compra {
    @include flexCol(flex-start, flex-start);
    padding: 110px 32px 50px;
    
    @include md {
        padding-top: 130px;
        padding: 130px 50px 50px;
    }
    
    @include xl {
        padding: 140px 50px 50px;
        flex-direction: row;
    }

    &__index {
        @include flexCol(flex-start, flex-start);
        width: 100%;
        padding-bottom: 40px;
        line-height: 22px;
        font-size: 13px;

        ul {
            list-style: none;
            text-align: start;
            line-height: 22px;
            font-size: 13px;

            li {
                cursor: pointer;
      
                &:hover {
                  color: $primary;
                  font-weight: 600;
                }
            }
        }

        &--subindex {
            list-style-type: circle !important;
            margin-left: 30px;
        }
    }

    &__info {

        @include xl {
            max-width: 75%;
        }

        h1 {
            padding-bottom: 0.8rem;
            @include primaryText(1.563rem);
            font-weight: 600;

            @include xl {
                text-align: start;
            }

        }

        h2, h3 {
            @include primaryText(1.375rem);
            text-transform: uppercase;
            padding-bottom: 0.6rem;
            font-weight: 500;
        }
        
        h3 {
            padding-top: 18px;
        }

        p {
            text-align: justify;
            font-size: 0.875rem;
        }
    }
    
}