@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.skeleton-pedido-card {
  height: 228px;
    
  @include xl {
    height: 102px;
  }

  &__id {
        @include skeletonBasics(13px, 50px, 3px);
        margin-bottom: 4px;
  }
      
  &__fecha {
    @include skeletonBasics(12px, 75px, 3px);
  }

  &__nombre {
    @include skeletonBasics(13px, 110px, 3px);
  }

  &__modelo {
    @include skeletonBasics(13px, 80px, 3px);
  }
      
  &__title {
    @include skeletonBasics(10px, 50px, 3px);
    margin-bottom: 4px;
  }

  &__precio {
    @include skeletonBasics(12px, 80px, 3px);

    @include xl {
      width: 65px;
    }
  }
      
  &__acciones {
    border-color: $btnFotos;

    &--accion {
      gap: 4px !important;

      @include xlMax {
        width: 80px !important;
      }

      &--icon {
        border-color: $btnFotos !important;
        margin-bottom: 6px;
      }
  
      &--text1 {
        @include skeletonBasics(8px, 65px, 3px);
      }
      &--text2 {
        @include skeletonBasics(8px, 42px, 3px);
      }
      
      &--text3 {
        @include skeletonBasics(8px, 47px, 3px);
      }
    }
    
  }
      
  
  }

  .pedido-card-loading-animation {
    @include skeletonBasicsAnimation($btnFotos, $skeletonSecondary);
  }