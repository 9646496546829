@import '../../../../scss/theme/variables';
@import '../../../../scss/theme/breakpoints';
@import '../../../../scss/styles/mixins';

.skeleton-pieza-card {
  &__nombre {
    @include skeletonBasics(14px, 180px, 3px);
    margin-bottom: 7px;

    @include lg {
      height: 18px;
      width: 240px;
    }

    @include custom(1300px) {
      height: 22px;
      width: 290px;
      margin-bottom: 10px;
    }
  }
  &__modelo {
    @include skeletonBasics(12px, 110px, 3px);
    margin-bottom: 10px;
    
    @include lg {
      height: 14px;
      width: 150px;
    }

    @include custom(1300px) {
      height: 20px;
      margin-bottom: 16px;
    }
  }

  &__labels {
    @include flexRow(flex-start, flex-start);
    gap: 8px;
    width: 100%;
    padding-top: 3px;
    &--label1 {
      @include skeletonBasics(21px, 121px, 10px);
      
      @include custom(1300px) {
        height: 29px; 
        width: 171px;
      }
    }

    &--label2 {
      @include skeletonBasics(21px, 90px, 10px);

      @include custom(1300px) {
        height: 29px;
        width: 121px;
        margin-bottom: 10px;
      }
    }
  }
  
  &__observaciones {
    @include skeletonBasics(8px, 90px, 10px);
    margin-top: 10px;
    margin-bottom: 80px;

    @include custom(1300px) {
      height: 14px;
      width: 150px;
    }
  }
  

  &__precio {
    @include skeletonBasics(14px, 120px, 3px);
    
    @include lg {
      height: 18px;
      width: 60px;
    }

    @include custom(1300px) {
      height: 24px;
      width: 65px;
      margin-top: 3px;
    }
  }
  
  &__precio2 {
    @include skeletonBasics(10px, 100px, 3px);
    margin-top: 5px;

    @include custom(1300px) {
      height: 12px;
    }
  }
  
  &__disponible {
    @include skeletonBasics(9px, 67px, 3px);

    @include lg {
      height: 16px;
      padding: 0;
      width: 90px;
    }
  }

  &__cart {
    border: none !important;
  }
}

.pieza-card-loading-animation {
  @include skeletonBasicsAnimation($btnFotos, $skeletonSecondary);
}