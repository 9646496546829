@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.busqueda-drop-down {
    @include flexCol(flex-end, flex-end);
    width: 100%;
    padding: 16px 0;
    gap: 10px;

    @include xl {
        max-width: 490px;
    }

    &__each-filter {
        display: grid;
        width: 100%;
        grid-template-columns: 1.3fr 1fr 0.7fr 1fr;
        align-items: center;
        
        p {
            font-size: 11px;
            max-width: 80px;

            @include sm {
                max-width: none !important;
            }

            @include md {
                padding-right: 12px;
            }

            @include md {
                font-size: 14px;
            }
        }

        
        &--hasta {
            justify-self: center;
            
            @include md {
                padding-right: 0 !important;
            }
        }
        
        input {
            @include basicInput();
            @include smMax {
                width: 97px;
            }
        }
        
        &--input-date {
            @include basicInputDate();
        }
    }

    &__button {
        padding-top: 10px;
        display: grid;
        grid-template-columns: 3fr 1fr;
        width: 100%;

        
        button {
            @include button(100%, 32px, auto);
            border-radius: 6px;
            grid-area: 1/2;
            position: relative;

            @include smMax {
                width: 97px;
            }
        }
    }
}