@import '../../../../../../scss/styles/mixins';
@import '../../../../../../scss/theme/breakpoints';
@import '../../../../../../scss/theme/variables';

.modal-detalle-each-card {
    background-color: $btnFilterBg;
    border-radius: 6px;
    margin-bottom: 10px;

    &__main {
        @include flexRow(center, flex-start);
        padding: 10px;
        gap: 14px;
        position: relative;

        @include lg {
            display: grid;
            grid-template-columns: 0.7fr 1.8fr 2.5fr 0.5fr 0.5fr 0.3fr;
            gap: 5px;
        }
        
        @include xl {
            grid-template-columns: 0.6fr 1.9fr 2.5fr 0.5fr 0.5fr 0.3fr;
        }

        &--img {
           height: 49px;
           border-radius: 6px; 
           background-color: $white;
           width: 53px; 
           overflow: hidden;
           @include flexCol(center, center);

           @include lg {
            width: 70px;
            height: 58px;
           }
           
           img {
               width: 100%;
               height: 100%;
               object-fit: contain;
               border-radius: 6px;
           }
        }

        &--text {
            @include flexCol(flex-start, flex-start);
            
            p {
                @include capitalize();
                font-size: 14px;
            }

            p:first-child {
                @include capitalize();
                @include primaryText(15px);
                font-weight: 600;
                padding: 0;
                padding-bottom: 1px;
            }
        }

        &--price, &--envio {
            font-weight: 600;
        }

        &--arrow {
            cursor: pointer;
            @include grayText(20px);

            @include lgMax {
                position: absolute;
                right: 16px;
            }
        }
    }

    &__dropdown {
        &--section-price {
            margin: 0 10px;
            border-top: 1px solid $grayColor;
            padding-top: 10px;

            p:first-child {
                @include grayText(10px);
                padding: 7px 10px;
            }

            &--grid {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                padding: 2px 0px !important;
            }

            &--oem-id {
                @include flexRow(flex-start, flex-start);
                gap: 10px;
                padding: 15px 0 14px;
                p {
                    background-color: $white;
                    padding: 4px 10px !important;
                    border-radius: 6px;
                    color: $primary !important;
                    font-size: 14px !important;

                    @include lg {
                        font-size: 12px !important;
                    }

                    @include xl {
                        font-size: 14px !important;
                    }

                    span {
                        font-weight: 600;
                    }
                }
            }
        }
        
        &--more-info {
            padding: 13px;
            
            @include lg {
                @include flexRow(flex-start, flex-start);
                border-top: 1px solid $grayColor;
                padding: 13px 0;
                margin: 0 13px;
            }

            &--sku-obs {
                @include flexCol(flex-start, flex-start);
                gap: 20px;

                @include lg {
                    gap: 10px;
                    width: 40%;
                    padding-right: 40px
                }

                &--sku {
                    @include flexCol(flex-start, flex-start);
                    font-size: 13px;

                    span {
                        font-weight: 500;
                    }
                }

                &--obs {
                    @include flexCol(flex-start, flex-start);
                    gap: 1px;

                    span {
                        @include capitalize();
                        font-size: 13px;
                        font-weight: 300;
                    }

                    span:first-child {
                        font-weight: 400;
                    }
                }
            }
        }

        &--info-pieza {
            padding-top: 16px;

            @include lg {
                padding-top: 0;
                display: grid;
                grid-template-columns: 1.5fr 1fr 1fr;
                width: 60%;
                gap: 22px;
                
                p, span {
                    font-size: 12px !important;
                    padding-bottom: 8px !important;
                }
            }
        }
    }
}