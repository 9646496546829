@import '../../../scss/theme/variables';
@import '../../../scss/theme/breakpoints';
@import '../../../scss/styles/mixins';

.pieza-detalle {
    padding-top: 85px;
    width: 100%;
    @include flexCol(flex-start, flex-start);

    @include sm {
        padding: 85px 39px 0;
    }

    @include md {
        padding: 100px 100px 0;
    }

    @include lg {
        padding: 120px 16px 16px;
        
        &__container {
            @include flexRow(center, flex-start);
            height: 622px;
            width: 100%;
        }
    }

    @include custom(1400px) {
        width: 1400px;
        margin: auto;
    }

    &__header {
        margin-left: 16px;
        margin-bottom: 20px;
    }


    &__whatsapp {
        padding-right: 56px;
    }

    &__container {
        width: 100%;
    }

    .extra-info-text {
        padding: 60px 16px 100px;

        @include lg {
            padding-top: 100px;
        }
    }

    .whatsapp-section {
        @include mdMax {
            margin-top: -70px;
        }
    }

}

