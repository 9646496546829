@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.modal-detalles {

    .modal-container__container {
        display: block;
        unicode-bidi: isolate;
        min-height: 520px;
    }
    h2 {
        padding-top: 5px;
        padding-bottom: 16px;
    }

    &__titles {
        display: grid;
        grid-template-columns: 1.3fr 1fr 1fr 1fr;
        padding: 25px 0 5px 10px;
        @include grayText(10px);
        
        @include lg {
            grid-template-columns: 1.3fr 1fr 1fr 1fr 1fr 1fr;
            text-align: center;

            span {
                max-width: 100px;
                align-self: flex-end;
                justify-self: center;
                padding-bottom: 5px;
            }

            span:first-child {
                text-align: start;
                justify-self: flex-start;
            }
        }
    }
    
    &__pedido {
        background-color: $btnFilterBg;
        border-radius: 8px;
        padding: 13px 0 13px 10px;
        display: grid;
        grid-template-columns: 1.3fr 1fr 1fr 1fr;
        font-size: 14px;
        @include lg {
            grid-template-columns: 1.3fr 1fr 1fr 1fr 1fr 1fr;
            
            p {
                @include flexCol(center, center);
            }
        }

        &--codigo {
            @include flexCol(flex-start, flex-start);
            font-size: 13px;
            max-width: 80px;

            @include lg {
                align-items: flex-start !important;
            }

            span:first-child {
                @include primaryText(14px);
                font-weight: 500;
                text-decoration: underline;
                padding-bottom: 2px
            }
        }

        &--compañia, &--transporte {
            @include flexCol(flex-start, flex-start);
            padding-top: 20px;

            @include lg {
                align-items: center;
                justify-content: center;
                padding-top: 0;
                padding-bottom: 0;
            }
            
            @include lgMax {
                span:first-child {
                @include grayText(10px);
                padding-bottom: 4px;
            }}
        }

        &--compañia {

            @include lg {
                span {
                    @include flexCol(center, center);
                }
            }

            img {
                width: 53px;
            }
        }

        &--transporte {
            @include lgMax {
                grid-column: 2/4;
            }
            
            &--text {
                text-decoration: underline;
            }
        }
    }

    &__piezas {
        padding-top: 20px;

        @include lg {
            padding-top: 35px;

            & > p {
                display: grid;
                grid-template-columns: 5fr 0.5fr 0.5fr 0.3fr;
                gap: 5px;
            }
        }

        & > p:first-child {
            @include grayText(10px);
            padding: 7px 10px;
        }
    }
        
}