@import '../../../scss/main.scss';

.modal-container {
    @include modalBackdrop();
    &__container {
        @include modalContainer();
        min-height: 100%;
        @include flexCol(center, center);
        gap: 5px;
        z-index: 202;

        &--close {
            @include modalBtnClose();
        }
    }
    &__overlay-to-close {
        width: 100vw;
        height: 100vh;
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 201;
        display: block;
    }
}
