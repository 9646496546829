@import '../../../scss/main.scss';

.auth-modal {
    &.guest {

        @include sm {
            padding: 25% 10%;
        }

        @include md {
            padding: 18% 25%;
        }

        @include lg {
            padding: 10% 25%;
        }

    }
    @include sm {
        .modal-container__container {
            @include bgImg('../../../images/login/RectangleMb.webp'); 
        }
    }
    &__logo {
        position: absolute;
        top: 19px;
        left: 16px;
        height: 18px;
        width: 141px;
    }
}

.form-login {
    @include flexCol(center, center);
    width: 100%;

    @include md {
        width: 50%;
        margin: 0;
        align-items: flex-start;
    }

    &.form-guest {
        @include smMax {
            @include bgImg('../../../images/login/RectangleMb.webp');
            border-radius: 8px !important;
            margin-top: 40px;
        }
    }
    &.form-login-guest {
        height: auto !important;
    }

    &__container {
        @include flexCol(center, center);
        width: 90%;
        min-height: 450px;
        border-radius: 8px;
        padding: 16px 0;
        margin: auto;
        margin-top: 1rem;

        &.form-register,
        &.verify-register,
        &.form-guest {
            height: auto;
        }

        &.form-guest {
            margin-top: 0px;
            @include smMax {
                background: transparent !important;
            }
            
            @include sm {
                min-height: auto;
            }

            h3 {
                padding-bottom: 32px;
            }
        }

        &.form-login-guest {
            padding-bottom: 0 !important;
            height: auto;
            @include smMax {
                background: transparent !important;
            }
        }

        @include smMax {
            @include bgImg('../../../images/login/RectangleMb.webp');
        }

        @include sm {
            width: 80%;
        }

        @include md {
            width: 100%;
            // min-height: 680px;
            margin: 0;
        }

        &.verify-register {
            @include lg {
                min-height: auto;
            }
            h2,
            p {
                width: 288px;

                @include lg {
                    width: 390px;
                }
            }

            h2 {
                color: $primary;
                font-weight: 500;
                font-size: 28px;
                padding-bottom: 12px;

                @include lg {
                    font-size: 32px;
                }
            }

            p.verify-register-subtitle {
                font-size: 15px;
                padding-bottom: 5px;

                @include lg {
                    padding-bottom: 30px;
                    font-size: 17px;
                }
            }
        }

        &--title {
            font-size: 18px;
            font-weight: 700;
            padding-bottom: 0.7rem;

            @include md {
                font-size: 22px;
                padding-bottom: 1.5rem;
            }

            @include lg {
                padding-bottom: 3rem;

                &.form-register {
                    padding-bottom: 2rem;
                }
            }
        }

        &--guest {
            @include flexRow(center, space-between);
            margin: 16px 0;
            width: 288px;
            color: $grayColor;

            @include md {
              font-size: 18px;
            }

            @include lg {
              width: 390px;
              font-size: 19px;
              margin: 26px 0;
            }
            
            span {
              border-top: 1px solid $primary;
              width: 136px;
              margin-top: 1px;
              
              @include lg {
                width: 186px;
              }
            }
        }

        &--guest-btn {
            @include flexCol(center, center);
            width: 288px;
            gap: 18px;

            @include lg {
                width: 390px;
                font-size: 19px;
            }

            button {
                @include button(100%, 36px, 0);
                cursor: pointer;
                position: relative;
                background-color: transparent;
                border: 1px solid $primary;
                color: $primary;
                font-weight: 400;

                &:hover {
                    background-color: $btnFotos;
                }

                @include lg {
                    font-size: 16px;
                    height: 46px;
                }

                &.primary-btn {
                    background-color: $primary;
                    color: $white;

                    &:hover {
                        background-color: $primary-hover;
                    }
                }
            }

            p {
                color: $grayColor;
                cursor: pointer;
                text-decoration: underline;
                font-size: 14px;

                @include lg {
                    font-size: 16px;
                }
            }
        }

        &--form {
            @include flexCol(center, space-evenly);
            min-height: 255px;

            &.verify-register {
                min-height: 140px;
            }

            @include md {
                justify-content: center;
                gap: 30px;

                &.form-register,
                &.verify-register {
                    gap: 16px;
                }
            }

            @include lg {
                width: 380px;

                &.form-register,
                &.verify-register {
                    gap: 10px;
                    min-height: 445px;

                    label {
                        font-size: 15px !important;
                        height: 68px;
                    }

                    input {
                        height: 43px !important;
                    }

                    button {
                        font-size: 16px !important;
                        height: 45px;
                        margin-top: 5px;
                        font-weight: 300;
                    }
                }

                &.verify-register {
                    min-height: auto;
                }
            }

            div {
                width: 288px;

                @include lg {
                    width: 390px;
                }

                label {
                    @include basicLabelAuth();

                    input {
                        @include basicInputAuth();
                    }

                    @include basicResetInput();
                }
            }

            &--labeltxt {
                @include flexRow(flex-start, space-between);
                width: 288px;

                @include lg {
                    width: 390px;
                }

                &--olvidado {
                    color: $fontPrimary;
                    font-size: 12px;
                    cursor: pointer;

                    @include lg {
                        font-size: 13px;
                    }
                }
            }

            &--eye {
                @include basicEyeAuth();
            }

            button {
                @include button(100%, 36px, 0);
                cursor: pointer;
                position: relative;

                @include lg {
                    height: 46px;
                    width: 390px;
                    font-size: 20px;
                }
            }

            &--error {
                @include basicErrorAuth();
            }
        }

        &--nocuenta {
            @include flexCol(center, center);
            font-size: 13px;
            width: 50%;
            line-height: 15.41px;

            &.form-register {
                @include mdMax {
                    padding-top: 16px;
                }
            }

            &.verify-register {
                display: inline-block;
                line-height: 18px;
            }

            &.form-guest {
                width: 100%;
                padding-top: 16px;
                font-size: 16px;
                line-height: 20px;

                @include md {
                    font-size: 18px;
                    line-height: 26px;
                }
            }

            @include md {
                padding-top: 1.6rem;

                &.verify-register {
                    padding-top: 0;
                }
            }

            @include lg {
                flex-direction: row;
                gap: 5px;
                font-size: 14px;
                padding-top: 3.5rem;
                width: 100%;

                &.form-register {
                    padding-top: 16px;
                }

                &.verify-register {
                    padding-top: 1.5rem;
                }
            }

            @include xl {
                font-size: 16px;
            }

            &--link {
                color: $primary;
                cursor: pointer;

                &:link,
                &:visited,
                &:active {
                    text-decoration: none;
                }
            }
        }

        div.verify-register-send-again {
            @include flexCol(center, center);
            padding-top: 32px;
            width: 100%;
            p {
                text-align: center;
                text-decoration: underline;
                font-size: 14px;
                color: $primary;
                cursor: pointer;

                &:hover {
                    color: $primary-hover-link;
                }

                @include lg {
                    font-size: 15px;
                }
            }
        }
    }
}
