@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';


.desk-carousel {
    @include flexCol(center, flex-start);
    width: 50%;
    padding: 22px 16px 45px;
    height: 100%;

    &__big-picture {
        width: 100%;
        position: relative;
        border: 2px solid $btnFilterBg;
        border-radius: 10px;
        overflow: hidden;
        height: 75%;
        cursor: pointer;

        &--text {
            position: absolute;
            top: 0px;
            z-index: 30;
            @include flexRow(center, space-between);
            width: 100%;
            padding: 0 25px 0 12px;
    
            &--stock {
                font-size: 16px;
                border: 1px solid $primary;
                color: $primary;
                border-radius: 10px;
                padding: 4px 19px;
                margin-top: 12px;
                background-color: $white;
            }
    
            &--discount {
                font-size: 16px;
                background-color: $primary;
                color: $white;
                font-weight: 500;
                padding: 8px 16px;
                border-radius: 0 0 10px 10px;
                margin-top: -2px;

                span {
                    font-size: 20px;
                }
            }
        }

        &--img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    
    &__slider {
        max-width: 640px;
        height: 25%;
        padding: 16px 0 0;
        @include flexRow(center, center); 

        span {
            @include flexCol(center, center);
            height: 32px;
            width: 56px;
            border: 1px solid $primary;
            font-size: 16px;
            border-radius: 8px;
            cursor: pointer;
        }

        &--btn-disactive {
            opacity: 0.4;
        }

        
        
        &--container-imgs {
            width: 100%;
            scroll-behavior: smooth;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            text-align: center;
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            scroll-snap-type: x mandatory;
            margin: 0 17px;
            // max-height: 110px;
            
            &::-webkit-scrollbar {
                display: none
            }

            &--li {
                list-style: none;
                display: inline-block;
                position: relative;
                vertical-align: middle;
                transition: 0.2s;
                scroll-snap-align: center;
                width: 33.3%;
                max-height: 110px;

                &--img {
                    @include flexCol(center, center);
                    text-align: center;
                    width: 100%;
                    cursor: pointer;
                    border: 2px solid $btnFilterBg;
                    border-radius: 10px;
                    max-height: 110px; 
                    object-fit: contain;
                }
            }
        }
        
        
    }

    
}