@import '../../../scss/theme/variables';
@import '../../../scss/theme/breakpoints';
@import '../../../scss/styles/mixins';

.total-pedido {

    h2 {
        color: $primary;
        padding: 0 6px 12px;

        @include custom(1300px) {
            font-size: 32px;
            font-weight: 500;

        }
    }
    
    p {
        @include flexRow(center, space-between);
        font-size: 13px;
        padding: 0 6px 6px;

        @include custom(1300px) {
            font-size: 17px;
        }

        span:first-child {
            color: $grayColor;

            @include custom(1300px) {
                font-size: 16px;
            }
        }
    }

    &__impuestos {
        padding-bottom: 16px !important;
    }

    h4 {
        @include flexRow(center, space-between);
        background-color: $default;
        color: $white;
        font-size: 15px;
        padding: 10px;     
        border-radius: 6px;

        @include custom(1300px) {
            font-size: 17px;

            span:first-child {
                font-size: 16px;
            }
        }
    }
    
    &__transporte {
        font-size: 13px !important;
        font-style: italic;
        color: $grayColor;
        padding-top: 13px !important;
    }

}