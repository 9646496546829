@import '../../../../scss/theme/variables';
@import '../../../../scss/theme/breakpoints';
@import '../../../../scss/styles/mixins';

.info-detalle {
    padding: 22px 16px 45px;
    width: 100%;
    height: 500px;

    @include lg {
        width: 50%;
        height: 100%;
    }
    
    &__container {
        background-color: $btnFilterBg;
        padding: 60px 16px 16px;
        border-radius: 6px;
        position: relative;
        height: 100%;

        @include lg {
            padding-top: 95px;
        }

        &--stock {
            position: absolute;
            top: 2px;
            left: 6px;

            @include lg {
                top: 14px;
                left: 12px;
            }

            .uppercase {
                text-transform: uppercase;
                background-color: $primary;
                color: $white;
                font-size: 10px;
                border-radius: 3px;
                padding: 2px 10px;

                @include lg {
                    font-size: 14px;
                    padding: 5px 14px;
                }
            }
        }

        &--disponible {
            position: absolute;
            top: 6px;
            right: 6px;
            background-color: $primaryLight;
            border-radius: 3px;
            padding: 2px 8px;
            @include primaryText(10px);
            
            @include lg {
                font-size: 14px;
                top: 12px;
                right: 12px;
                padding: 4px 10px;
            }
        }
        
        &--nombre {
            @include primaryText(16px);
            padding-bottom: 6px;
            @include capitalize();
            
            @include custom(590px) {
                font-size: 18px;  
            }
            
            @include lg {
                font-size: 24px;
            }
        }
        
        &--modelo {
            font-size: 14px;
            font-weight: 400;
            text-transform: uppercase;

            @include custom(590px) {
                font-size: 15px;
            }

            @include lg {
                font-size: 22px;
            }
        }
        
        &--labels {
            @include flexRow(center, flex-start);
            padding: 10px 0 12px;
            gap: 6px;
            @include primaryText(12px);

            @include lg {
                font-size: 16px;
                padding: 16px 0 18px;
            }

            span {
                background-color: $white;
                padding: 4px 16px;
                border-radius: 6px;
                min-width: 100px;

                @include lg {
                    padding: 6px 18px;
                    border-radius: 10px;
                    min-width: 130px;
                }
            }
        }

        &--observaciones {
            font-size: 13px;
            font-weight: 300;
            padding-bottom: 30px;
        }
        
        &--sku-ref {
            font-size: 13px;
            @include flexRow(center, flex-start);
            gap: 45px;
            padding-bottom: 26px;

            @include lg {
                padding-top: 20px;
                padding-bottom: 28px;
            }

            &--sku, &--ref {
                @include flexCol(flex-start, flex-start);
            
                @include lg {
                    font-size: 16px;
                    span {
                        font-size: 14px;
                    }
                }
            }
        }

        &--more-info {
            font-size: 13px;
            padding-bottom: 12px;

            @include lg {
                font-size: 14px;
            }

            &.to-uppercase span {
                text-transform: uppercase;
            }

            span {
                font-weight: 500;

                @include lg {
                    font-size: 16px;
                }
            }
        }

        &--add-cart {
            @include flexCol(flex-end, flex-end);
            position: absolute;
            bottom: 10px;
            right: 10px;

            @include lg {
                margin-top: -30px
            }

            h3 {
                @include flexCol(flex-end, flex-end);
                padding-bottom: 14px;
                @include primaryText(22px);

                @include lg {
                    font-size: 26px;
                }

                span {
                    font-size: 11px;
                    font-weight: 300;
                    color: $fontPrimary;

                    @include lg {
                        font-size: 12px;
                    }
                }
            }

            &--old-price {
                padding-bottom: 14px;
                margin-top: -14px;
                text-decoration: line-through;
                font-size: 20px;

                @include lg {
                    font-size: 24px;
                }

            }

            p {
                background-color: $white;
                width: 180px;
                height: 40px;
                @include flexRow(center, flex-start);
                gap: 16px;
                font-weight: 500;
                @include primaryText(14px);
                border-radius: 8px;
                padding: 4px;
                cursor: pointer;

                &:hover {
                    box-shadow: 2px 2px 10px $boxShadow;
                }

                &:active {
                    -webkit-transform: scale(0.98);
                    transform: scale(0.98);
                }

                &.info-detalle__container--add-cart--disabled-to-add {
                    opacity: 0.7;
                    cursor: default;

                    &:active {
                        -webkit-transform: none !important;
                        transform: none !important;
                    }

                    &:hover {
                        box-shadow: none !important;
                    }
                }
    
                
                @include lg {
                    height: 56px;
                    width: 258px;
                    padding: 9px;
                    font-size: 20px;
                }

                span:first-child {
                    @include flexCol(center, center);
                    background-color: $primaryLight;
                    font-size: 26px;
                    width: 32px;
                    height: 32px;
                    border-radius: 6px;
                    position: relative;

                    @include lg {
                        height: 37px;
                        width: 37px;
                    }
                }

            }

            &--no-disponible {
                padding-bottom: 10px;
                @include flexCol(flex-end, flex-end);
                gap: 4px;
                h4 {
                    color: $danger;
                    font-weight: 500;
                    font-size: 22px;
                }
                span {
                    font-size: 13.5px;
                    font-style: italic;
                    text-align: end;
                }
            }
        }

        &--favorites {
            position: absolute;
            top: 30px;
            right: 6px;
            font-size: 12px;

            span {
                padding: 3px 8px;
                border-radius: 3px;

                @include lg {
                    padding: 3px 10px;
                }
            }

            &:active {
                -webkit-transform: scale(0.96);
                transform: scale(0.96);
            }

            &--disabled {
                opacity: 0.7;
                cursor: default !important;
                
                &:active {
                    -webkit-transform: none !important;
                    transform: none !important;
                }
            }
            
            @include lg {
                top: 47px;
                right: 12px;
                font-size: 18px;
            }
            
        }

        &--no-data-btns {
           @include flexRow(flex-end, flex-end);
           gap: 16px; 

           &--whats {
            @include primaryText(32px);
           }

           &--email {
            @include primaryText(34px);
           }

           &--whats, &--email {
            background-color: white;
            cursor: pointer;
            height: 54px;
            width: 54px;
            box-shadow: 0px 0px 10px $boxShadow;
            border-radius:6px;
            @include flexCol(center, center);
        }
        }

    }

    &__en-carrito {
        top: 6px;
        right: 7px;
        padding: 2px 10px;

        @include lg {
            top: 11px;
            right: 12px;
            font-size: 14px;
            padding: 5px 14px;
        }
    }
}