@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.mySwiper {
    @include flexCol(center, center);
    padding: 0 3px;
    padding: 16px;
    @include sm {
        padding: auto;
        margin: auto;
    }
    
    & > div {
        border: 2px solid $btnFilterBg;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        width: 100%;
    }
    
    &__text {
        position: absolute;
        top: 0px;
        z-index: 30;
        @include flexRow(center, space-between);
        width: 100%;
        padding: 0 18px 0 10px;
        &--stock {
            border: 1px solid $primary;
            @include primaryText(10px);
            border-radius: 5px;
            padding: 2px 16px;
            margin-top: 9px;
            background-color: $white;
        }
        &--discount {
            font-size: 12px;
            background-color: $primary;
            color: $white;
            font-weight: 500;
            padding: 8px 14px;
            border-radius: 0 0 10px 10px;
        }
    }

    &__img {

        .img-default {
            width: 100% !important;
        }
    }
    
}

.swiper {
    height: 100%;
}

.swiper-slide {
    align-self: center;
    text-align: center;
    font-size: 18px;
    background: #fff;
    width: 100% !important;
    
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

