@import '../../../scss/main.scss';

.extra-info-text {
  text-align: justify;
  padding: 70px 16px 20px;
  @include flexCol(flex-start, flex-start);
  gap: 16px;
  line-height: 26px;
  
  @include md {
    padding: 60px 0 50px;
  }

  @include lg {
    padding: 32px 0 90px;
  }

  h3 {
    @include primaryText(19px);
    line-height: 29px;

    @include md {
      font-size: 21px;
    }
  }

  p {
    font-size: 15px;
  }

  strong {
    color: $primary;
  }
}