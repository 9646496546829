@import '../../../../../../scss/styles/mixins';
@import '../../../../../../scss/theme/breakpoints';
@import '../../../../../../scss/theme/variables';

.card-each-seguimiento {
    background-color: $btnFilterBg;
    border-radius: 8px;
    margin: 6px 0 20px;
    width: 100%;

    &__container {
        padding: 13px;
        position: relative;
        height: fit-content !important;

        @include lg {
            @include flexRow(center, center);
        }
        
        &--info {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1.5fr;
            height: 100% !important;
            
            @include lg {
                grid-template-columns: 1.1fr 1.7fr 1fr 1fr 1.5fr;
                height: 65px;
                align-items: center;
            }

            &.dropdown-seguimiento-open {
                padding-bottom: 16px;
                border-bottom: 1px solid $grayColor;
            }
            
            p {
                @include flexCol(flex-start, flex-start);
                padding-bottom: 22px;

                @include lg {
                    padding-bottom: 0;
                }
            }
            
            &--title {
                @include grayText(10px);
                padding-bottom: 5px;

                @include lg {
                    display: none;
                }
            }
            
            &--num-envio {
                color: $primary;
                font-size: 15px;
                font-weight: 500;
                text-decoration: underline;
            }

            &--fecha {
                font-size: 13px;
                padding-top: 4px;
            }

            &--contenido {
                max-width: 170px;
                color: $primary;
                font-size: 16px !important;
                font-weight: 500;

                @include lg {
                    max-width: none;
                }
            }

            &--ref, &--transporte {
                font-size: 14px;

                @include lg {
                    text-align: center;
                    margin: auto;
                }
            }

            &--estado-container {
                padding-bottom: 0px !important;

                @include lg {
                    align-self: center;
                    justify-self: center;
                }


                &--estado {
                    background-color: $desconocidoColor;
                    border-radius: 4px;
                    font-size: 12px;
                    font-weight: 500;
                    width: 112px;
                    min-height: 24px;
                    padding: 5px 6px;
                    @include flexCol(center, center);

                    &.entregado {
                        background-color: $primary;
                        color: $white;
                    }
                }
            }

            
        }

        &--arrow {
            cursor: pointer;
            @include grayText(20px);
            top: 10px;
            position: absolute;
            right: 10px;

            @include lg {
                top: 40%;
                @include flexCol(center, center);

                &.dropdown-seguimiento-open {
                    top: 33%;
                }
            }
        }
    }
}