@import "./variables";

@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}

@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}

@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}
 
@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}

@mixin custom($screen) {
    @media (min-width: $screen) {
        @content;
    }
}

@mixin smMax {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}

@mixin mdMax {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}

@mixin lgMax {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
}

@mixin xlMax {
    @media (max-width: #{$screen-xl-max}) {
        @content;
    }
}
