@import '../../../../scss/theme/variables';
@import '../../../../scss/theme/breakpoints';
@import '../../../../scss/styles/mixins';

.skeleton-info-detalle {
  width: 100%;

  @include lg {
    width: 50%;
  }

  &__disponible {
    @include skeletonBasics(13px, 70px, 3px);
  }
  
  &__nombre {
    @include skeletonBasics(16px, 200px, 3px);
    margin-bottom: 10px;
  }

  &__modelo {
    @include skeletonBasics(14px, 90px, 3px);
    margin-bottom: 5px;
  }

  &__label1 {
    @include skeletonBasics(21px, 121px, 10px);
    padding: 0 !important;
    background-color: $btnFotos !important;
  }

  &__label2 {
    @include skeletonBasics(21px, 90px, 10px);
    padding: 0 !important;
    background-color: $btnFotos !important;
  }
  
  &__observaciones {
    @include skeletonBasics(12px, 200px, 3px);
    padding: 0 !important;
  }
  
  &__sku {
    @include skeletonBasics(14px, 35px, 3px);
    margin-top: 20px;
  }
  &__sku2 {
    @include skeletonBasics(14px, 75px, 3px);
    margin-top: 5px;
  }

  &__version {
    @include skeletonBasics(14px, 230px, 3px);
    margin-top: 10px;
  }
  &__motor {
    @include skeletonBasics(14px, 180px, 3px);
    margin-top: 16px;
  }
  &__combustible {
    @include skeletonBasics(14px, 170px, 3px);
    margin-top: 16px;
  }
  &__año {
    @include skeletonBasics(14px, 90px, 3px);
    margin-top: 16px;
  }
  &__km {
    @include skeletonBasics(14px, 100px, 3px);
    margin-top: 16px;
  }
  &__puertas {
    @include skeletonBasics(14px, 80px, 3px);
    margin-top: 16px;
  }
  &__color {
    @include skeletonBasics(14px, 100px, 3px);
    margin-top: 16px;
  }
  &__tipo-motor {
    @include skeletonBasics(14px, 120px, 3px);
    margin-top: 16px;
  }     
  &__tipo-cambio {
    @include skeletonBasics(14px, 130px, 3px);
    margin-top: 16px;
  }
  &__precio1 {
    @include skeletonBasics(20px, 55px, 3px);
  }
  &__precio2 {
    @include skeletonBasics(12px, 80px, 3px);
    margin-top: 6px;
  }
  &__btn {
    padding: 0 !important;
    background-color: $btnFotos !important;
  }
      
}

.info-detalle-loading-animation {
  @include skeletonBasicsAnimation($btnFotos, $skeletonSecondary);
}