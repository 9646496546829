@import '../../../scss/theme/variables';
@import '../../../scss/theme/breakpoints';
@import '../../../scss/styles/mixins';

.resumen-carrito {
    padding-top: 90px;
    width: 100%;
    @include flexCol(center, flex-start);

    @include sm {
        padding: 90px 7% 0;
    }

    @include md {
        padding: 120px 10% 50px;
    }

    @include lg {
        padding-top: 135px;
    }

    &__detalle {
        padding: 0 16px;
        max-width: 950px;
        width: 100%;

        &.empty-cart {
            padding-bottom: 40px;
        }

        &--return {
            button {
                @include flexRow(center, center);
                gap: 6px;
                background-color: $white;
                @include primaryText(13px);
                border: 1px solid $primary;
                border-radius: 6px;
                padding: 6px 9px;
                font-weight: 500;
                cursor: pointer;
            }
        }

        &--title {
            @include primaryText(24px);
            padding: 40px 0 28px;

            @include lg {
                font-size: 36px;
            }
        }

        &--piezas {
            max-width: none;
            height: auto;
        }

        &--entrega {
            font-size: 12px;
            padding: 20px 6px 36px;

            @include lg {
                font-size: 14px;
            }
        }
    }

    &__pedido-section {
        max-width: 950px;
        width: 100%;
        padding: 30px 16px 80px;
        position: relative;
        @include flexCol(flex-end, flex-end);

        &-div {
            @include flexCol(flex-start, flex-start);
            width: 100%;
            gap: 20px;
            border-radius: 8px;
            margin-bottom: 16px;

            @include lg {
                margin-bottom: 8px;
                border-radius: 12px;
                box-shadow: 3px 3px 25px $boxShadow;
                padding: 24px 12px;
                width: 40%;
            }
        }

        button {
            @include basicsAddNewButton();
            height: auto;
            padding: 6px 12px 6px 6px;
            position: relative;

            &:active {
                -webkit-transform: scale(0.98);
                transform: scale(0.98);
            }

            @include lg {
                font-size: 16px;
            }

            span.icon-tramitar-pedido {
                font-size: 26px;
                height: 32px;
                width: 32px;
            }
        }

        &--disabled-btn {
            -webkit-transform: none;
            transform: none;
            opacity: 0.7;
            cursor: not-allowed !important;

            &:active {
                transform: scale(1) !important;
            }
        }

        &--total {
            width: 100%;

            h2 {
                display: none;
            }

            h4 {
                font-weight: 400;
                padding: 10px 7px !important;
            }
        }
    }
}

.MuiAlert-standard {
    width: 100% !important;
    font-size: 13px !important;
    @include lg {
        font-size: 12px !important;
    }
    @include custom(1060px) {
        font-size: 13px !important;
    }
}
