@import '../../../../scss/theme/variables';
@import '../../../../scss/theme/breakpoints';
@import '../../../../scss/styles/mixins';

.pedidos-realizados {
    @include basicsEachSectionAreaPersonal();

    @include md {
        padding-left: 80px;
        padding-right: 55px;
    }
    
    @include xl {
        max-width: 1200px;
        padding-left: 40px;
        padding-right: 24px;
    }
    
    @include custom(1300px) {
        padding-left: 80px;
        padding-right: 55px;

    }
    
    &__return {
        @include returnArrowAreaPersonal();
    }

    h2 {
        @include titleAreaPersonal();
        padding-bottom: 3px;

        @include xl {
            padding-bottom: 9px;
        }
    }

    h3 {
        font-size: 14px;
        font-weight: 300;

        @include xl {
            font-size: 17px;
            font-weight: 400;
        }
    }

    &__search-section {
        @include flexCol(flex-end, flex-end);
        gap: 12px;
        width: 100%;
        padding: 14px 0;

        @include xl {
            padding: 32px 0 42px;
        }

        &--container {
            width: 100%;

            @include xl {
                @include flexRow(center, space-between);
            }

            &--search {
                @include basicInputSearch();
            }

            &--busqueda {
                @include basicBusquedaAvanzada();
                padding-top:16px;

                @include xl {
                    padding-top: 0;
                }
            }
        }

        
    }

}