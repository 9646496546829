@import '../../../../../../scss/styles/mixins';
@import '../../../../../../scss/theme/breakpoints';
@import '../../../../../../scss/theme/variables';

.skeleton-tipo-incidencia {
  border-color: $btnFotos;
  &__title {
    @include skeletonBasics(14px, 100px, 3px);
    margin-bottom: 4px;
  }

  &__text1 {
    @include skeletonBasics(11px, 180px, 3px);
  }
  &__text2 {
    @include skeletonBasics(11px, 200px, 3px);
  }
  
  &__text3 {
    @include skeletonBasics(11px, 120px, 3px);
  }
}

.tipo-incidencia-loading-animation {
  @include skeletonBasicsAnimation($btnFotos, $skeletonSecondary);
}