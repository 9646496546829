@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.skeleton-dir-card {
  @include basicsCardDireccion();
  border-color: $btnFilterBg;
  height: 74px;
  padding-right: 2px;

  &__btns {
    @include flexRow(center, flex-end);
    gap: 6px;
  }

}

.line{
  width: 250px;
  border-radius: 6px;
}

.line1{
  height: 11px;
  width: 200px;
}

.line2{
    margin-top: 10px;
    height: 7px;
  }

  .line3{
    width: 80%;
    height: 7px;
    margin-top: 5px;
  }

  .btn-delete-edit {
    width: 24px;
    height: 24px;
    border-radius: 5px;

    @include lg {
      width: 38px;
      height: 38px;
      border-radius: 12px;
    }
  }

  .loading-animation{
    @include skeletonBasicsAnimation($skeletonPrimary, $skeletonSecondary);
  }
