@import '../../../../../../scss/styles/mixins';
@import '../../../../../../scss/theme/breakpoints';
@import '../../../../../../scss/theme/variables';

.choose-pieza-card {
  @include flexRow(center, center);
  gap: 10px;

  &__checkbox {
    @include inputCheckbox();
  }

  &__text {
    @include flexRow(flex-start, center);
    gap: 3px;

    &--tipo {
      @include capitalize();
      color: $primary;
      font-weight: 500;
    }

    &--marca, &--modelo {
      @include capitalize();
      font-weight: 300;
    }
  }

}