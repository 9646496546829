@import '../../../scss/theme/variables';
@import '../../../scss/styles/mixins';
@import '../../../scss/theme/breakpoints';

.simple-spinner {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 77px;
    top: 15px;
    transform: translate(-50%, -50%);
}

.simple-spinner span {
    display: block;
    width: 25px;
    height: 25px;
    border: 3px solid transparent;
    border-radius: 50%;
    border: 2px;
    border-right: 1px solid $grayColor;
    opacity: 0.8;
    animation: spinner-anim 0.8s linear infinite;
}

.spinner-busqueda-drop-down {
    top: 15px;
    left: 50%;
}

.spinner-busqueda-drop-down span {
    border-right-color: $btnFotos;
    width: 22px;
    height: 22px;
}

.spinner-descargar-factura {
    left: 48%;
    top: 48%;

    @include xl {
        width: 13px;
        height: 13px;
        left: 9px;
        top: 0;
    }
}

.spinner-descargar-factura span {
    width: 22px;
    height: 22px;
    border: 2px;
    border-right: 1px solid $grayColor;

    @include xl {
        width: 13px;
        height: 13px;
    }
}

.spinner-nueva-incidencia {
    left: 50%;
    top: 16px;
}

.spinner-nueva-incidencia span {
    border-right-color: $primaryLight;
}

.spinner-form-dir-envio {
    padding-top: 0 !important;
    top: 49%;
    height: 25px !important;
    width: 25px !important;
}

.spinner-eliminar-pieza {
    height: 15px;
    width: 15px;
    left: 50%;
    top: 49%;

    @include lg {
        height: 22px;
        width: 22px;
    }
}

.spinner-form-dir-envio span, .spinner-eliminar-pieza span {
    height: 100% !important;
    width: 100% !important;
}

.spinner-login {
    height: 28px !important;
    width: 28px !important;
    left: 50%;
    top: 49%;

    @include lg {
        height: 36px !important;
        width: 36px !important;
    }
}

.spinner-contact-form {
    height: 23px !important;
    width: 23px !important;
    left: 50%;
    top: 49%;

    @include md {
        height: 28px !important;
        width: 28px !important;
    }
}

.spinner-login span, .spinner-contact-form span {
    height: 100%;
    width: 100%;
    border-right-color: $primaryLight;
}

.spinner-contact-form.guest span {
    border-right-color: $primaryLight !important;
}

.spinner-pieza-card {
    top: 50%;
    left: 50%;
}

.spinner-pieza-card span {
    height: 100%;
    width: 100%;
    border-right-color: $primary;
}

.spinner-pieza-detalle, .spinner-favoritos-card {
    top: 49%;
    left: 49%;
}

.spinner-pieza-detalle span {
    border-radius: 50% !important;
    width: 23px !important;
    height: 23px !important;
    border-right-color: $primary !important;

    @include lg {
        width: 27px !important;
        height: 27px !important;
    }
}

.spinner-favoritos-card span {
    width: 22px !important;
    height: 22px !important;
    border-right-color: $primary !important;
}

.spinner-resumen-carrito {
    left: 22px;
    top: 22px;
    background-color: transparent !important;
}

.spinner-resumen-carrito span {
    width: 21px !important;
    height: 21px !important;
    border-right-color: $primary !important;
    display: block !important;
    border-radius: 50% !important;
    margin-right: 0 !important;
}

.simple-spinner-reenvio-email-search {
    left: 273px !important;
    top: 14px;
    width: 16px !important;
    height: 16px !important;

    @include lg {
        top: 26px !important;
        left: 375px !important;
        width: 20px !important;
        height: 20px !important;
    }
}

.simple-spinner-reenvio-email-search span {
    width: 16px !important;
    height: 16px !important;

    @include lg {
        width: 20px !important;
        height: 20px !important;
    }
}

@keyframes spinner-anim {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}
