@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.skeleton-mob-carousel {
 width: calc(100vw - 17px);
 height: 75vw;
 padding: 16px;

 @include sm {
  width: calc(100vw - 95px);
  height: 66vw;
 }

 @include md {
  width: calc(100vw - 217px);
  height: 55vw;
 }

 &__container {
  width: 100%;
  height: 100%;
  overflow:hidden;
  background-color: $btnFilterBg;
  border-radius: 10px;

  &--img {
    width: 100%;
    height: 100%;
  }

 }
}

.skeleton-desk-carousel {
  &__big-picture {
    height: 34vw;
    margin-top: 3px;
  }

  &__slider {
    width: 100%;
  }

  &__imgs {
    margin-top: 10px;
    
    &--img {
      border-color: $btnFilterBg !important;
      width: 98% !important;
      height: 8vw !important;
    }
  }
}


.carousel-loading-animation {
  @include skeletonBasicsAnimation($btnFilterBg, $btnFotos);
}