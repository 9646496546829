@import '../../../../scss/styles/mixins';
@import '../../../../scss/theme/variables';
@import '../../../../scss/theme/breakpoints';

.skeleton-detalle-carrito {

  @include lg {
    height: 120px;
  }

  @include xl {
    height: 140px;
  }

  @include custom(1300px) {
    max-width: none !important;
    height: 150px;
  }

  &__foto {
    &--img {
      background-color: $btnFotos !important;
    }
  }

  &__title {
    @include skeletonBasics(8px, 115px, 3px);

    @include lg {
      height: 15px;
      width: 200px;
    }

    @include xl {
      height: 18px;
      width: 270px;
      margin-bottom: 8px;
    }
  }
  
  &__subtitle {
    @include skeletonBasics(8px, 90px, 3px);
    margin-top: 3px;

    @include lg {
      height: 13px;
      width: 110px;
    }

    @include xl {
      height: 15px;
      width: 123px;
    }
  }
  
  &__labels {
    width: 100%;
    @include lgMax {
      display: none;
    }
    
    &--label1 {
      background-color: $btnFotos !important;
      padding: 0 !important;
      @include skeletonBasics(20px, 110px, 3px);

      @include xl {
        height: 24px;
        width: 125px;
      }
    }
    
    &--observaciones {
      @include skeletonBasics(10px, 110px, 3px);

      @include lgMax {
        display: none;
      }

      @include xl {
        height: 11px;
        width: 130px;
      }
    }
  }
  
  &__precio {
    @include skeletonBasics(12px, 30px, 3px);

    @include lg {
      height: 19px;
      width: 40px;
    }
  }
  
  &__eliminar {
    border: none !important;
  }
}

.detalle-carrito-loading-animation {
  @include skeletonBasicsAnimation($btnFotos, $skeletonSecondary);
}