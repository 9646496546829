@import '../../../../../../scss/styles/mixins';
@import '../../../../../../scss/theme/breakpoints';
@import '../../../../../../scss/theme/variables';

.skeleton-detalle-each-card {
  &__nombre {
    @include skeletonBasics(13px, 90px, 3px);
    margin-bottom: 6px;
  }
  
  &__modelo {
    @include skeletonBasics(13px, 120px, 3px);

  }

  &__labels {
    @include flexRow(flex-start, flex-start);
    gap: 8px;
    width: 100%;
    padding-top: 3px;
    &--label1 {
      @include skeletonBasics(21px, 121px, 10px);
      padding: 0 !important;

      @include custom(1300px) {
        height: 25px; 
        width: 150px;
      }
    }
    
    &--label2 {
      @include skeletonBasics(21px, 90px, 10px);
      padding: 0 !important;
      
      @include custom(1300px) {
        height: 25px;
        width: 100px;
        margin-bottom: 10px;
      }
    }
  }
  
  &__precio {
    @include skeletonBasics(18px, 70px, 3px);
  }
  
  &__envio {
    @include skeletonBasics(18px, 60px, 3px);
  }
}

.detalle-each-card-loading-animation {
  @include skeletonBasicsAnimation($btnFotos, $skeletonSecondary);
}