@import '../../../../../scss/styles/mixins';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/theme/variables';

.modal-zoom-image {
  position: fixed;
  z-index: 11000;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(73, 73, 73, 0.9),#000 1810%);

  &__container {
      opacity: 1;
      transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      position: fixed;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      right: 0;
      bottom: 0;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      -webkit-tap-highlight-color: transparent;
      z-index: -1;
      width: 100%;
      height: 100%;

      &--header {
          width: 100%;
          height: 45px;
          position: absolute;
          top: 0;
          left: 0;
    
          .toolbar {
              height: 100%;
              position: absolute;
              z-index: 3;
              right: 5px;
              top: 0;
              display: flex;
              background: rgba(35,35,35,.1);
              padding: 15px;
              font-size: 1.4rem;
              column-gap: 10px;
              display: flex;
              align-items: center;
              color: #fff;
              cursor: pointer;
            }
          }
          
        }
        
        
        .swiper {
          margin-left: auto;
          margin-right: auto;
        }
        
        .swiper-slide {
          background-size: cover;
          background-position: center;
          background: rgba(35,35,35,.1);
          
          
      img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
      }
  }
    
  .swiperPrincipal {
      height: 100%;
      width: 100%;

      .swiper-slide {
          img { 
              height: 80vh !important; 
              object-fit: contain;
          }
      }

      .swiper-button-prev::after, .swiper-button-next::after {
          font-size: 1.5rem !important;
      }
  }

  .swiper-pagination {
    span {
      background-color: #fff;
    }
  }

  .swiper-zoom-container {
    background-color: $white;
  }
}