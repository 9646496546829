@import '../../../../../../scss/theme/variables';
@import '../../../../../../scss/theme/breakpoints';
@import '../../../../../../scss/styles/mixins';

.skeleton-estado-row {
  height: 106px;

  @include custom(427px) {
    height: 89px;
  }

  @include md {
    height: 55px;
  }

  &__fecha {
    @include skeletonBasics(15px, 120px, 3px);

    @include md {
      display: none;
    }
  }

  &__fecha-container {
    @include flexCol(flex-start, flex-start);
    gap: 4px;

    @include mdMax {
      display: none;
    }
    
    &--fecha2 {
      @include skeletonBasics(15px, 90px, 3px);
      align-self: start !important;
    }
    
    &--fecha3 {
      @include skeletonBasics(15px, 70px, 3px);
      align-self: start !important;
    }
  }
  
  &__estado {
    @include skeletonBasics(15px, 140px, 3px);

    @include md {
      width: 100px;
    }
  }
  
  &__comentario {
    margin-top: 30px;
    gap: 7px;

    @include custom(427px) {
      margin-top: 15px
    }

    @include md {
      margin-top: 0;
    }

    &--title {
      @include skeletonBasics(10px, 40px, 3px);

      @include md {
        display: none;
      }
    }
    
    &--text {
      @include skeletonBasics(15px, 220px, 3px);
      
      @include md {
        align-self: start !important;
      }
    }
    
  }
}

.skeleton-each-file {
  padding: 0 !important;
  
  span {
    background-color: $btnFotos;
  }
}

.skeleton-comentarios-cliente {
  @include skeletonBasics(15px, 220px, 3px);
}

.detalle-incidencia-loading-animation {
  @include skeletonBasicsAnimation($btnFotos, $skeletonSecondary);
}