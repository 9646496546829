@import '../../../../scss/theme/variables';
@import '../../../../scss/theme/breakpoints';
@import '../../../../scss/styles/mixins';

.no-data-card {
    background-color: $btnFilterBg;
    width: 100%;
    @include flexCol(center, center);
    gap: 30px;
    border-radius: 8px;
    padding: 45px 30px;

    &__text {
      @include flexCol(center, center);
      gap: 10px;
      text-align: center;

      &--1 {
        font-size: 20px;
      }
      
      &--2 {
        @include primaryText(36px);
        font-weight: 600;
        padding: 6px 0;
      }
      
      &--3 {
        font-size: 20px;
      }
    }

    &__icons {
      @include flexRow(center, center);
      gap: 30px;

      &--whats {
        @include primaryText(32px);
      }
      
      &--email {
        @include primaryText(34px);
      }
      
      &--whats, &--email {
        cursor: pointer;
        height: 54px;
        width: 54px;
        box-shadow: 0px 0px 10px $boxShadow;
        border-radius:6px;
        @include flexCol(center, center);
      }
    }
}