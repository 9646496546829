@import '../../../../../scss/theme/variables';
@import '../../../../../scss/theme/breakpoints';
@import '../../../../../scss/styles/mixins';

.price-filter {
    padding: 6px 16px;
    
    @include md {
        width: 280px;
        padding: 6px 0;
    }
    
    @include lg {
        width: 312px;
    }

    @include xl {
        width: 100%;
    }

    &__btn {
        @include flexRow(center, space-between);
        width: 100%;
        height: 43px;
        background-color: $btnFilterBg;
        border-radius: 10px;
        padding: 0 16px;

        @include xl{
            height: 48px;
            padding: 0 20px;
        }

        &--nombre {
            color: $primary;
            font-size: 15px;
            font-weight: 600;

            @include xl {
                font-size: 17px;
            }
        }

        &--borrar {
            @include grayText(12px);
            cursor: pointer;
            @include flexRow(center, flex-end);
            gap: 5px;
            
            @include xl {
                font-size: 13px;
                gap: 9px;
            }
        }
    }

    &__inputs {
        @include flexRow(center, space-between);
        width: 100%;
        padding: 12px 30px 7px;

        @include custom(432px) {
            justify-content: center;
            gap: 43px;
        }

        @include md {
            gap: 12px;
            justify-content: flex-start;
            padding: 12px 44px 7px 12px;
        }

        div {
            @include flexRow(center, space-between);
            width: 113px;

            input {
                width: 91px;
                height: 32px;
                border-radius: 4px;
                border: 1px solid $primary;
                padding: 10px;
                font-weight: 500;
            }
        }
    }

    &__error {
        @include basicErrorAuth();
        padding-top: 0;
        padding-left: 12px;
        font-size: 12px;

        @include lg {
            font-size: 12px;
        }
        span {
            padding-top: 1px;
        }
    }
}