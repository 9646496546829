//COLORS:
$primary: #215732;
$primary-hover-link: #194226;
$primary-hover: #20532f;
$primaryLight: #DAF9E4;
$primaryLightHover: #cbf5d8;
$default: #212529;
$fontPrimary: #000;
$backgroundGray: #F5F5F5;
$footer: #212529;
$white: #FFF;
$boxShadow: rgba(171, 171, 171, .25);
$danger: #dc3545;
$btnFilterBg: #F5F5F7;
$btnFilterBgSkeleton: #f2f1f1;
$grayColor: #525252;
$desconocidoColor: #CBCBCB;
$btnFotos: #E8E8E8;
$skeletonPrimary: #f9f9f9;
$skeletonSecondary: #f4f4f4;

//BREAKPOINTS:
$screen-sm-min: 576px;
$screen-sm-max: 575px;
$screen-md-min: 768px;
$screen-md-max: 767px;
$screen-lg-min: 992px;
$screen-lg-max: 991px;
$screen-xl-min: 1200px;
$screen-xl-max: 1199px;