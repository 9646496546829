@import '../../../scss/main.scss';

.navbar {
    position: fixed;
    @include flexRow(center, space-between);
    height: 60px;
    width: 100%;
    padding: 0.5rem 0rem;
    background-color: $white;
    color: $primary;
    box-shadow: 2px 2px 15px #ababab25;
    z-index: 100;

    @include md {
        height: 75px;
    }

    @include lg {
        height: 90px;
    }

    &__logo {
        text-decoration: none;
        margin-left: 1rem;

        img {
            height: 18px;
            width: 141px;
        }

        @include md {
            margin-left: 55px;
        }       
    }

    &__hamburger {
        @include basicIconsDeskNav();
        @include basicIconsMobNav();
        right: 25px;
        display: none;
        font-size: 2rem;
        padding: 0.2rem;
        
        @include mdMax {
            display: block;
        }
        
    }

    &__menu {
        @include md {
            @include flexRow(center, flex-end);
            margin-right: 55px;
            gap: 10px;
        }

        ul {
            display: flex;
            padding: 0;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            position: relative;

            @include mdMax {
                flex-direction: column;
                position: absolute;
                top: 60px;
                left: 0;
                width: 100%;
                background-color: $white;
                display: none;
                padding: 1.5rem 0 2.5rem;
                font-weight: 500;
            }

            li {
                list-style-type: none;
                margin: 0 1rem;
                text-align: center;
                font-size: 13px;
                
                @include mdMax {
                    text-align: center;
                    margin: 0;
                    font-size: 15px;
                    &:hover {
                        background-color: $backgroundGray;
                    }
                }
                
                @include lg {
                    font-size: 14px;
                }

                @include xl {
                    font-size: 16px;
                }
                
                a {
                    text-decoration: none;
                    display: block;
                    width: 100%;
                    color: $primary;

                    @include mdMax {
                        padding: 1.2rem 0;
                    }
                }
            }

            .active {
                font-weight: 700;
            }
  
        }
        
        &--expanded {
            ul {
                @include mdMax {
                    box-shadow: 6px 3px 6px #ababab25;
                    display: block;
                }
            }  
        }

        &--comercial {
            
            @include mdMax {
                right: 175px !important;
            }
        }

        &--cart {
            @include basicIconsDeskNav();
            position: relative;
            height: 40px;
            width: 40px;
            font-size: 1.35rem;
            padding: 0.54rem;

            @include mdMax(){
                @include basicIconsMobNav();
                padding: 0.54rem;
                font-size: 1.35rem;

                &--expanded {
                    display: block;
                    position: absolute;
                    right: 125px;
                }
            }

            @include lg {
                width: 57px;
                height: 57px;
                border-radius: 12px;
                padding: 0.56rem;
                
                &--icon {
                    @include flexCol(center, center);
                    background-color: $primaryLight;
                    width: 38px;
                    height: 38px;
                    border-radius: 12px;
                    font-size: 1.4rem;
                }
                
            }

            &--number {
                @include flexCol(center, center);
                position: absolute;
                top: -4px;
                right: -4px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: $white;
                border: 1px solid $primary;
                font-size: 0.7rem;
                font-weight: 600;

                @include md {
                    padding-top: 1px;
                }

                @include lg {
                    top: 4px;
                    right: 4px;    
                }
            }
        }

        &--user {
            @include basicIconsDeskNav();
            
            @include mdMax(){
                @include basicIconsMobNav();
                padding: 9px;
                right: 75px;
                font-size: 1.2rem;
                display: block;
            }

            @include md {
                padding: 9px;
                width: 40px;
                height: 40px;
                font-size: 1.2rem
            }
            
            @include lg {
                @include flexRow(center, flex-start);
                border-radius: 12px;
                gap: 12px;
                width: 200px;
                height: 57px;
                padding: 0.56rem;

                &.no-user {
                    width: 170px;
                }
                
                &--icon {
                    @include flexCol(center, center);
                    background-color: $primaryLight;
                    width: 38px;
                    height: 38px;
                    border-radius: 12px;
                    font-size: 1.1rem;
                }
            }
            
            &--desk {
                display: none;

                @include lg {
                    @include flexCol(flex-start, flex-start);
                    max-width: 72%;
                    overflow: hidden;
                    
                    &--name {
                        font-size: 16px;
                        font-weight: 700;
                        line-height: 21px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 100%;
                    }
                    
                    &--area {
                        font-size: 14px;
                        font-weight: 300;
                    }
                    
                    &--login {
                        font-size: 14px;
                        font-weight: 600;
                    }
                }

            }
        }
    }
  }
  