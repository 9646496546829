@import '../../../../scss/main.scss';

.info-filter {
  width: 100%;
  
  &__container {
    @include flexRow(center, flex-start);
    gap: 5px;
    flex-wrap: wrap;

    &--back {
      @include primaryText(23px);
      cursor: pointer;
      margin-right: 2px;
    }

    &--clickable {
      cursor: pointer;
      text-transform: capitalize;
      color: $grayColor;
      font-size: 14px;

      &:hover {
        text-decoration: underline;
      }
    }

  }
}