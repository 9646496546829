@import '../../../../scss/main.scss';

.modal-send-email {
    &__title {
        @include primaryText(30px);
    }

    &__subtitle {
        font-weight: 500;
        padding-bottom: 30px;
    }

    form {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
}
