@import '../../scss/theme/breakpoints';
@import '../../scss/styles/mixins';
@import '../../scss/theme/variables';

.reset-password, .register {
  @include flexCol(center, center);
  width: 100%;
  height: 100vh;
  padding: 32px;
  position: relative;

  &__logo {
    position: absolute;
    top: 24px;
    left: 32px;
    height: 28px;
    width: 219px;
  }

  &__container {
    width: 100%;
    @include flexCol(center, center);
    background-color: $btnFilterBg;
    border-radius: 10px;
    padding: 40px 0;

    @include sm {
      padding: 50px 0;
      width: 70%;
    }

    @include lg {
      max-width: 700px;
    }

    &--title {
      font-size: 20px;
      text-align: center;
      padding-bottom: 30px;
      max-width: 180px;

      @include sm {
        padding-bottom: 40px;
        font-size: 25px;
      }

      @include md {
        max-width: none;
        padding-bottom: 50px
      }
    }

    &--form {
      @include flexCol(flex-start, space-evenly);
      gap: 10px;

      div {
        width: 288px;

        @include lg {
            width: 390px;
        }

        label {
            @include basicLabelAuth();
            
            input {
                @include basicInputAuth();
            }

            @include basicResetInput();
        }
      }

      &--eye {
          @include basicEyeAuth();

          &--orange {
            color: orange;
          }
      }

      button {
        @include button(100%, 36px, 0);
        cursor: pointer;
        position: relative;

        @include lg {
            height: 50px;
            width: 390px;
            font-size: 20px;
        }
      }

      &--btn {
        margin-top: 10px;
      }

      &--error {
        @include basicErrorAuth();
      }

      &--list {
        padding: 15px 0 20px;

        h4 {
          font-size: 14px;
          padding-bottom: 7px;
        }

        ul {
          padding: 0 20px;
          font-size: 13px;
          line-height: 19px;
        }
      }
    }
  }  
}

.register {
  padding-top: 130px !important;
}