@import '../../../scss/theme/variables';
@import '../../../scss/styles/mixins';
@import '../../../scss/theme/breakpoints';

.payment {
  width: 100%;
  @include flexRow(center, center);
  gap: 24px;
  padding: 12px;
  border-top: 1px solid $boxShadow;

  div {
    @include flexCol(center, center);
    width: auto;
    
    .visa, .mastercard, .bizum {
      height: 25px;
    }

    .visa {
      width: 70px;
    }

    .mastercard {
      width: 40px;
    }

    .bizum {
      width: 80px;
    }

    .transfer {
      width: 31px;
      height: 30px;
    }
   
  }
}
.footer {
  background-color: $footer;
  color: $white;
  padding: 50px 40px 20px;
  width: 100%;
  
  @include md {
      padding: 60px 50px 30px;
  }

    a {
      color: $white;
      text-decoration: none;
    }

    &__container {
      @include flexCol(center, center);
      text-align: center;

      .social-media-content {
        @include flexRow(center, center);
        gap: 10px;

        &__linkedin {
          width: 22px;
          height: 22px;
        }
        
        &__instagram {
          width: 23px;
          height: 23px;
        }
      }

      @media (max-width: 768px) {
        .social-media-content {
          justify-content: center;
          padding-top: 0.5rem;
        }

        .footer-wrapper:not(:last-child) {
          padding-bottom: 30px;
        }
        
      }

      @include md {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 2.2rem;
      }

      @include lg {
        justify-content: center;
        gap: 60px;
      }

      @include xl {
        gap: 100px;
      }

      @include custom(1400px) {
        gap: 173px;
      }
    }

    &-wrapper {

      @include flexCol(center, space-around);

      @include md {
        align-items: flex-start;
        text-align: start;

        .trabaja-con {
          width: 5.4rem;
        }
      }

        h3 {
            color: $white;
            font-size: 1rem !important;
            font-weight: 600 !important;
            text-transform: uppercase !important;
            padding: 0;
            margin: 0;

            @media (max-width: 768px) {
              padding: 0.2rem 0 !important;
            }
        }

        p { 
            font-size: 0.9rem !important;
            padding: 0;
            margin: 0;
        }

        a, span { 
          padding-bottom: 5px;
          font-size: 0.9rem;
          cursor: pointer;
        }

        div {
          @include flexCol(center, space-around);
          padding-top: 0.9rem;

          @include md {
            align-items: flex-start;
            padding-top: 1rem;
          }
        }

        &__img {
          padding-bottom: 1rem;
          height: 34px;
          width: 144px;

          @include md {
            height: 18px;
            padding-bottom: 0;
          }
        }

        &__container {
          padding-top: 0.5rem;

          @include md {
            padding-top: 0
          }
        }

        .shipping {
          padding-top: 1rem;
          @include flexCol(flex-start, flex-start);
          gap: 14px;
          .mrw, .seur, .dhl, .txt {
            height: 12px;
          }
        }
    }

    &-info-privacity {
      @include flexRow(center, center);
      flex-wrap: wrap;
      gap: 3px 12px;
      text-align: center;
      
      @include xl {
        width: 50%;
      }
    }
  
    &-info {
      @include flexCol(center, center);
      padding-top: 3rem;
      a { 
        font-size: 0.75rem !important;
      }

      @media (max-width: 768px) {
        width: 100%;
        small { 
          font-size: 0.688rem !important;
        }
      }
    }

    .inicio {
      min-width: 100px;
      @include mdMax {
        padding-bottom: 19px;
      }
    }

    .blog, .trabaja {
      padding-top: 5px;
    }
    .copyright { 
      color: $white !important; 
      padding-bottom: 10px; 
    }

  }