@import '../../../../../../scss/styles/mixins';
@import '../../../../../../scss/theme/breakpoints';
@import '../../../../../../scss/theme/variables';

.skeleton-dir-seguimiento {
  @include flexCol(flex-start, flex-start);
  height: 85px;
  gap: 5px;

  &__text1 {
    @include skeletonBasics(11px, 180px, 3px);
  }
  &__text2 {
    @include skeletonBasics(11px, 90px, 3px);
  }
  &__text3 {
    @include skeletonBasics(11px, 150px, 3px);
  }
  &__text4 {
    @include skeletonBasics(11px, 100px, 3px);
  }
  &__text5 {
    @include skeletonBasics(11px, 120px, 3px);
  }
}

.dir-seguimiento-loading-animation{
  @include skeletonBasicsAnimation($btnFotos, $skeletonSecondary);
}
